import { SwiperOptions } from "swiper";

export interface TagSelectOption {
	id: string | boolean;
	name: string;
}

export enum TagSelectType {
	SINGLE_SELECT = 'single-select',
	MULTI_SELECT = 'multi-select',
}

export enum ButtonType {
	TEXT = 'text',
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',
	PRIMARY_EMPTY = 'primary-empty',
	SECONDARY_EMPTY = 'secondary-empty',
	TERTIARY_EMPTY = 'tertiary-empty',
	DANGER = 'danger'
}


export interface StepperBpConfig {
	[width: number]: SwiperOptions;
	[ratio: string]: SwiperOptions;
}

export enum WebsocketMessages {
	CONNECTIONID = 'connectionId',
	CONNECTION = 'connection',
	NEWCONVERSATIONADAM = 'new_conversation_adam',
	NEWMESSAGESEND = 'new_message_send_adam',
	NEWMESSAGERECEIVED = 'new_message_received_adam',
	READCONVERSATION = 'read_conversation_adam',
	DELETECONVERSATIONS = 'delete_list_conversation_adam',
	TITLEUPDATE = 'title_update_conversation_adam',
	PINUPDATE = 'pin_update_conversation_adam',
	DELETEMESSAGE = 'delete_msg_adam',
	CONVERSATIONDRIVEDELETE = 'conversation_drive_delete',
	CONVERSATIONDRIVEUPDATE = 'conversation_drive_update',
	CREATEUKNOWHOST = 'create_uknow_host',
	DELETEUKNOWHOST = 'delete_uknow_host',
	TTS = 'tts',
	UDRIVEIMPORTSTART = 'udrive_import_start',
	UDRIVEIMPORTEND = 'udrive_import_end',
	UPDATEMESSAGEADAM = 'update_message_adam',
	UPDATEIMAGEDESCRIPTION = 'update_image_description',
	SHOWINTERRUPTBUTTON = 'show_interrupt_button',
	HIDEINTERRUPTBUTTON = 'hide_interrupt_button',
	COINSSPENT = 'coin_spent',
	COINSNEW = 'new_coin',
	LOG = 'log',
	ERROR = 'error',
	PING = 'pong',
	LOGOUT = 'force_logout'
}


export enum ErrorApi {
	INV_PASS_MAIL = "INV_PASS_MAIL",
	BAN_USR = "BAN_USR",
	DIS_USR = "DIS_USR",
	INV_REF_TKN = "INV_REF_TKN",
	EXI_USR = "EXI_USR",
	INV_TOTP = "INV_TOTP",
	INV_PASS = "INV_PASS",
	INV_BDAY = "INV_BDAY",
	NOT_EXI_USR = "NOT_EXI_USR",
	ACC_LOCK = "ACC_LOCK"
}

export enum SectionModalType {
	PERSONAL_INFORMATION,
	PRIVACY,
	NOTIFICATION,
	FEEDBACK,
	SHARED
}