import { HttpClient, HttpContext, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subject, switchMap, take } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { API_URL } from '@constants/api.constant';
import { environment } from '@environments/environment';
import { BYPASS_LOG } from '../interceptors/auth.interceptor';
import { LoginModel } from '@models/login.model';
import { TimeUtils } from '@utils/times';
import { DecodedTokenModel } from '@models/decodedToken.model';
import { User, VerifyEmailRequest } from '@models/signUp.model';

const service: string = window.sessionStorage.getItem('maia_service') && window.sessionStorage.getItem('maia_service') !== '' ? window.sessionStorage.getItem('maia_service')! : environment.service;
const headers = new HttpHeaders().set('service', service);//.set('Access-Control-Allow-Origin', '*').set('ngrok-skip-browser-warning', 'edoardo');
const context = new HttpContext().set(BYPASS_LOG, true);

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private refreshTokenInProgress = false;
	private refreshTokenSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
	constructor(private http: HttpClient) { }
	public signIn(email: string, password: string) {
		return this.http
			.post<LoginModel>(API_URL.getAuthToken, { grant_type: 'password', authorization: window.btoa(`${email}:${password}`) }, { headers, context })
			.pipe(
				map((res: LoginModel) => {
					this.setToken(res);
					return res;
				})
			);
	}

	public signUp(body: VerifyEmailRequest | User): Observable<User> {
		return this.http
			.post<User>(API_URL.createUser, body, { headers, context })
			.pipe(
				map((res: User) => {
					return res;
				})
			);
	}

	public getRecoverPasswordCode(email: string) {
		return this.http.post<string>(
			API_URL.getForgotPassCode,
			{ email },
			{ headers, context }
		);
	}

	public resetPassword(obj: { email: string; totp: string; password: string }) {
		return this.http.post<string>(API_URL.getForgotPassCode, obj, {
			headers,
			context,
		});
	}

	public refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (!this.refreshTokenInProgress) {
			this.refreshTokenInProgress = true;
			this.refreshTokenSubject.next(true);
			return this.http
				.post<LoginModel>(API_URL.getAuthToken, { grant_type: 'refresh_token', refresh_token: localStorage.getItem('maia_refresh_token') }, { headers, context })
				.pipe(
					switchMap((res: LoginModel) => {
						this.setToken(res);
						const subscription = JSON.parse(localStorage.getItem('maia_user_detail') as string).subscriptionKey;
						const service: string = window.sessionStorage.getItem('maia_service') && window.sessionStorage.getItem('maia_service') !== '' ? window.sessionStorage.getItem('maia_service')! : environment.service;
						this.refreshTokenInProgress = false;
						this.refreshTokenSubject.next(false);
						return next.handle(
							request.clone({
								setHeaders: {
									Authorization: `Bearer ${localStorage.getItem(
										'maia_access_token'
									)}`,
									service: service,
									'ocp-apim-subscription-key': subscription,
								},
							})
						);
					})
				);
		} else {
			return this.refreshTokenSubject.pipe(
				filter(result => !result),
				take(1),
				switchMap(_ => {
					return next.handle(this.addAuthToken(request))
				})
			);
		}
	}


	addAuthToken(request: HttpRequest<any>) {
		const token = localStorage.getItem("maia_access_token");
		const subscription = JSON.parse(localStorage.getItem("maia_user_detail") as string).subscriptionKey;
		const service: string = window.sessionStorage.getItem('maia_service') && window.sessionStorage.getItem('maia_service') !== '' ? window.sessionStorage.getItem('maia_service')! : environment.service;
		return request.clone({
			setHeaders: {
				'Authorization': `Bearer ${token}`,
				'service': service,
				'ocp-apim-subscription-key': subscription
			}
		})
	}

	public cleanRefreshToken() {
		return this.http.post<LoginModel>(API_URL.getAuthToken, { grant_type: 'refresh_token', refresh_token: localStorage.getItem('maia_refresh_token') }, { headers, context })
	}

	public updatePassword(updateObj: { authorization: string, password: string }) {
		return this.http.post<string>(API_URL.updatePassCode, updateObj)
	}


	public deleteUser(obj: { reason: string | null }) {
		return this.http.post<string>(API_URL.deleteUser, obj, { observe: 'body' })
	}


	public isLoggedIn() {
		return this.getCurrTime() < this.getTokenTime();
	}

	private getCurrTime() {
		return TimeUtils.convertToTimestamp(TimeUtils.dateAdd(new Date(), 0, 0, 0, 0, 0, 3).toISOString());//add 3 seconds for prevent error token call and refresh
	}

	public getTokenTime() {
		return +localStorage.getItem('maia_expires_at')! * 1000;
	}

	public getToken() {
		return localStorage.getItem('maia_access_token') ? localStorage.getItem('maia_access_token') : null;
	}


	public setToken(res: LoginModel) {
		let decodeJwt = <DecodedTokenModel>jwt_decode(JSON.stringify(res));
		localStorage.setItem('maia_access_token', res.access_token);
		localStorage.setItem('maia_refresh_token', res.refresh_token);
		localStorage.setItem('maia_expires_at', JSON.stringify(decodeJwt.exp));
		localStorage.setItem('maia_user_detail', JSON.stringify(decodeJwt));
	}

	public deleteToken() {
		localStorage.removeItem('maia_user_detail');
		localStorage.removeItem('maia_access_token');
		//localStorage.removeItem('maia_connectionId');
		localStorage.removeItem('maia_refresh_token');
		localStorage.removeItem('maia_expires_at');
	}
}
