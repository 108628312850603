import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { API_URL } from '@constants/api.constant'
import { UknowCreateModel, UknowCreateProfileModel, UknowReadModel, UknowVerifiedHostModel } from '@models/uknow.model';
import { BYPASS_LOG } from '../interceptors/auth.interceptor';

const service: string = window.sessionStorage.getItem('maia_service') && window.sessionStorage.getItem('maia_service') !== '' ? window.sessionStorage.getItem('maia_service')! : environment.service;
const headers = new HttpHeaders().set('service', service);//.set('Access-Control-Allow-Origin', '*').set('ngrok-skip-browser-warning', 'edoardo');
const context = new HttpContext().set(BYPASS_LOG, true);


@Injectable({
	providedIn: 'root',
})
export class UKnowService {
	constructor(private http: HttpClient) { }


	verifyHost(host: string) {
		return this.http.post<UknowVerifiedHostModel>(API_URL.uknowVerify, { host }, { responseType: 'json' });
	};

	uknowCreate(body: UknowCreateModel) {
		return this.http.post<number>(API_URL.uknowCreate, body, { responseType: 'json' });
	};

	uknowUpdate(body: UknowCreateModel) {
		return this.http.post<number>(API_URL.uknowUpdate, body, { responseType: 'json' });
	};

	uknowDelete(uknowId: number) {
		return this.http.delete<any>(`${API_URL.uknowDelete}${uknowId}`, { responseType: 'json' });
	};

	uknowReadById(convId: string) {
		return this.http.get<UknowReadModel[]>(API_URL.uknowReadById + convId, { responseType: 'json' })
	};

	uknowReadAll() {
		return this.http.get<UknowReadModel[]>(API_URL.uknowReadAll, { responseType: 'json' })
	};

	uknowReadGuestConversation(convId: string, guestId: string) {
		return this.http.get<any>(`${API_URL.uknowReadGuestConversation}${guestId}/${convId}`, { responseType: 'json' })
	}

	uknowCreateProfile(body: UknowCreateProfileModel) {
		return this.http.post<number>(API_URL.uknowCreateProfile, body, { headers, context, responseType: 'json' });
	};

}