<div class="md:w-[50vw] lg:w-64 w-screen" *ngIf="true">
    <div class="flex flex-col text-center justify-center border-b border-gray-medium py-4 bg-white z-10 w-full sticky top-0 rounded-t-xl">
        <i class="icon-close text-maia-petrol-800 absolute left-3 text-2xl cursor-pointer" (click)="closeModal()"></i>
        <span class="font-bold">{{'COINS.COIN_OVER.modal_title' | translate}}</span>
    </div>
    <div class="flex flex-col py-4 justify-center items-center">
        <svg class="w-14 h-14" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5002 40.8652C28.2557 40.8652 28.8891 40.6097 29.4002 40.0986C29.9113 39.5875 30.1668 38.9541 30.1668 38.1986C30.1668 37.443 29.9113 36.8097 29.4002 36.2986C28.8891 35.7875 28.2557 35.5319 27.5002 35.5319C26.7446 35.5319 26.1113 35.7875 25.6002 36.2986C25.0891 36.8097 24.8335 37.443 24.8335 38.1986C24.8335 38.9541 25.0891 39.5875 25.6002 40.0986C26.1113 40.6097 26.7446 40.8652 27.5002 40.8652ZM24.8335 30.1986H30.1668V14.1986H24.8335V30.1986ZM27.5002 54.1986C23.8113 54.1986 20.3446 53.4986 17.1002 52.0986C13.8557 50.6986 11.0335 48.7986 8.6335 46.3986C6.2335 43.9986 4.3335 41.1763 2.9335 37.9319C1.5335 34.6875 0.833496 31.2208 0.833496 27.5319C0.833496 23.843 1.5335 20.3763 2.9335 17.1319C4.3335 13.8875 6.2335 11.0652 8.6335 8.66523C11.0335 6.26523 13.8557 4.36523 17.1002 2.96523C20.3446 1.56523 23.8113 0.865234 27.5002 0.865234C31.1891 0.865234 34.6557 1.56523 37.9002 2.96523C41.1446 4.36523 43.9668 6.26523 46.3668 8.66523C48.7668 11.0652 50.6668 13.8875 52.0668 17.1319C53.4668 20.3763 54.1668 23.843 54.1668 27.5319C54.1668 31.2208 53.4668 34.6875 52.0668 37.9319C50.6668 41.1763 48.7668 43.9986 46.3668 46.3986C43.9668 48.7986 41.1446 50.6986 37.9002 52.0986C34.6557 53.4986 31.1891 54.1986 27.5002 54.1986ZM27.5002 48.8652C33.4557 48.8652 38.5002 46.7986 42.6335 42.6652C46.7668 38.5319 48.8335 33.4875 48.8335 27.5319C48.8335 21.5763 46.7668 16.5319 42.6335 12.3986C38.5002 8.26523 33.4557 6.19857 27.5002 6.19857C21.5446 6.19857 16.5002 8.26523 12.3668 12.3986C8.2335 16.5319 6.16683 21.5763 6.16683 27.5319C6.16683 33.4875 8.2335 38.5319 12.3668 42.6652C16.5002 46.7986 21.5446 48.8652 27.5002 48.8652Z" fill="#EC8888" />
        </svg>
        <p [innerHTML]="'COINS.COIN_OVER.modal_msg' | translate" class="text-center mt-2 mb-8 font-medium"></p>
        <a href="https://mymaia.ai/shop/" target="_blank" class="bg-maia-petrol-800 hover:bg-maia-petrol-500 transition-colors flex rounded-xl px-2 py-1 text-xs text-white shadow-custom">
            {{'COINS.COIN_OVER.modal_call_to_action' | translate}}
        </a>
    </div>
</div>