import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { boardedGuard } from './guards/boarded.guard';
import { SubDomainGuard } from './guards/subdomain.guard';
import { AppRoutes } from '@utils/routes';
import { uknowResolver } from './resolvers/uknow-resolver.service';
import { forceRefreshGuard } from './guards/force-refresh.guard';
import { FlagBasedPreloadingStrategy } from '@services/flag-based.preloading-strategy';

const routes: Routes = [
	{
		path: AppRoutes.ON_BOARD,
		pathMatch: 'full',
		canMatch: [SubDomainGuard],
		loadChildren: () => import('@views/onboard/onboard.module').then((m) => m.OnBoardModule),
	},
	{
		path: AppRoutes.AUTH,
		pathMatch: 'full',
		canMatch: [SubDomainGuard],
		canActivate: [boardedGuard],
		loadChildren: () => import('@views/auth/auth.module').then((m) => m.AuthModule),
	},
	// {
	//     path: AppRoutes.SIGN_UP,
	//     pathMatch: 'full',
	//     component: SignUpComponent,
	//     canMatch: [SubDomainGuard],
	//     canActivate: [boardedGuard],
	//     data: { [animationStateProperty]: 2, [showBackgroundStateProperty]: true },
	// },
	{
		path: AppRoutes.SHARE + '/:id',
		pathMatch: 'full',
		canMatch: [SubDomainGuard],
		loadChildren: () => import('@views/chat-share/chat-share.module').then((m) => m.ChatShareModule),
	},
	{
		path: AppRoutes.UKNOW,
		pathMatch: 'prefix',
		loadChildren: () => import('@views/uknow-public/uknow-public.module').then((m) => m.UknowPublicModule),
		resolve: { attachments: uknowResolver }
	},
	{
		path: AppRoutes.UNDER_MAINTAINANCE,
		pathMatch: 'full',
		loadChildren: () => import('@views/under-maintainance/under-maintainance.module').then((m) => m.UnderMaintainanceModule),
	},
	{
		path: AppRoutes.FORCED_UPDATE,
		pathMatch: 'full',
		loadChildren: () => import('@views/forced-update/forced-update.module').then((m) => m.ForcedUpdateModule),
	},
	{
		path: '',
		canActivate: [boardedGuard],
		canMatch: [forceRefreshGuard],
		canActivateChild: [authGuard],
		loadChildren: () => import('@views/main/main.module').then((m) => m.MainModule),
		data: { preload: true }
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: FlagBasedPreloadingStrategy,
			useHash: false
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
