import { DetachFromChatModels } from "./chat.model";

export interface UdriveFileTreeModel {
	id: number;
	id_user: string;
	file_name: string;
	extension: string;
	notes: string[] | null;
	path: string;
	size: number;
	status: number;
	upload_date: string | null;
	embeddings: null;
	subfile: UdriveFileTreeModel[] | null;
	src: string | null;
	isExpanded?: boolean;
	emb_lang: string | null;
}

export interface UdriveSizesModel {
	uDriveSize: number,
	emailSize: number,
	calendarSize: number,
	totalSize: number,
	maxSize: number
}


export interface UdriveReadResponseModel {
	uDriveFiles: UdriveFileTreeModel[];
	uDriveSize: UdriveSizesModel;
	uploadCount: number;
	isPro: boolean;
	isPlus: boolean;
}

export interface DeleteAttachErrorModel {
	idUdrive: number;
	conversationList: DetachFromChatModels[]
}

export interface UdriveMailModel {
	id: number | null;
	id_user: string;
	email: string
}

export interface UdriveQuickActionModel {
	id_file: number;
	action: QuickActionType;
	msg?: string;
	lang?: string | null;
	conversationId?: string;
	id_adam_conv?: number | null;
	index_par?: number | null;
	isCreate?: boolean;
}

export interface UdriveQuickActionCacheModel {
	id: number;
	id_file: number;
	id_user: string;
	action: typeof QuickActionType;
	language: string | null;
	text: string | null;
}


export enum ActionType {
	ADDFILE = 'addFile',
	ADDFOLDER = 'addFolder',
	ADDURL = 'addUrl',
	EDITFILE = 'editFile',
	EDITFOLDER = 'editFolder',
	EDITURL = 'editUrl',
	EDITNOTES = 'editNotes',
	EDITEMAIL = 'editEmail',
	NONE = 'none'
}

export enum QuickActionType {
	SUMMARIZE = 'summarize',
	TRANSLATE = 'translate',
	PARAPHRASE = 'paraphrase',
	TRANSCRIBE = 'transcribe',
	DESCRIBE = 'describe'
}