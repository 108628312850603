import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonalGraphState } from '../app.interface';

export const selectPersonalGraphFeature =
	createFeatureSelector<PersonalGraphState>('PersonalGraphState');



export const getPpgData = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.ppgData
);


export const getPpgNode = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.selectedNode
)

export const getPpgSubNode = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.selectedSubNode
)

export const getPpgPath = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.graphPath
)

export const getPpgCurrNodelData = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.currNodeData
)

export const getPpgPersonalName = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.personalName
)

export const getPpgDefaultCat = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.ppgDefaultCat
)

export const getPpgBannedCat = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.ppgBannedCat
)

export const getPpgDictionary = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.ppgDictionary
)

export const getPpgDictionaryAndData = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => {
		return {
			data: state.ppgData,
			dictionary: state.ppgDefaultCat
		}
	}
)

export const getIsSavingMultiplePpg = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.ppgSavingNode
)


export const getPpgDictionaryNodes = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => {
		return {
			dictionary: state.ppgDictionary,
			node: state.selectedNode,
			subNode: state.selectedSubNode,
			personalDataNode: state.currNodeData
		}
	}
)

export const getPpgDisplayName = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.personalName
)


export const getPpgMultipleUpdate = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.ppgMultipleUpdate
)

export const getShowNodeStatus = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.showNodeSide
);

export const getShowDataStatus = createSelector(
	selectPersonalGraphFeature,
	(state: PersonalGraphState) => state.showDataSide
);


export const getTalkAboutStatus = createSelector(
	selectPersonalGraphFeature,
	({ talkAbout }: PersonalGraphState) => talkAbout
);

export const getLastPPGUpdate = createSelector(
	selectPersonalGraphFeature,
	({ lastUpdate }: PersonalGraphState) => lastUpdate
);
