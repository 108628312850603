import { createAction, props } from '@ngrx/store';
import { CudNodeModel, DefaultCategoriesModel, PpgData, PpgDataNode, PpgDictionaryModel } from '@models/personalGraph.model';


export const GetPPGData = createAction(
	'[PPG Store] Get PPG data',
	props<{ value: string }>()
);

export const SetPPGData = createAction(
	'[PPG Store] Set PPG data',
	props<{ ppgData: PpgData, ppgDictionary: PpgDictionaryModel[], ppgDefaultCat: DefaultCategoriesModel, ppgBannedCat: string[], personalName: string }>()
);

export const SetPPGDefaultCat = createAction(
	'[PPG Store] Set PPG Default Cat',
	props<{ value: any[] }>()
);


export const GetPPGBannedCat = createAction(
	'[PPG Store] Get PPG Banned Cat'
);

// export const SetPPGBannedCat = createAction(
//     '[PPG Store] Set PPG Banned Cat',
//     props<{ value: string[] }>()
// );

export const UpdatePPGBannedCat = createAction(
	'[PPG Store] Update PPG Banned Cat',
	props<{ value: string[] }>()
);

export const SetPPGCurrNode = createAction(
	'[PPG Store] Set PPG Current node',
	props<{ value: string }>()
);

export const SetPPGCurrSubNode = createAction(
	'[PPG Store] Set PPG Current sub node',
	props<{ value: number }>()
);
export const resetPPGCurrSubNode = createAction(
	'[PPG Store] Reset PPG Render sub current node'
);

export const SetPPGPersonalName = createAction(
	'[PPG Store] Set PPG Personal Name',
	props<{ value: string }>()
);

export const SetPPGCurrNodeData = createAction(
	'[PPG Store] Set PPG Current node Data',
	props<{ nodeData: PpgDataNode[] }>()
);


export const SetPPGPath = createAction(
	'[PPG Store] Set PPG Path',
	props<{ value: string[] }>()
);

export const SetPpgMultiple = createAction(
	'[PPG Store] Set PPG Multiple Update Node',
	props<{ value: CudNodeModel }>()
);

export const DeletePpgNode = createAction(
	'[PPG Store] Delete PPG Node',
	props<{ value: CudNodeModel }>()
);


export const RemovePpgMultipleCreateByValue = createAction(
	'[PPG Store] remove PPG Multiple Create Node by value',
	props<{ value: string[] }>()
);

export const SavePpgMultiple = createAction(
	'[PPG Store] Save PPG Multiple Delete/Update Node'
);

export const IsSavingPpgMultiple = createAction(
	'[PPG Store] Saving PPG Multiple state',
	props<{ value: boolean }>()
);


export const ResetPpgMultiple = createAction(
	'[PPG Store] Reset PPG Multiple Delete/Update Node'
);


export const ChangeNodeSideViewStatus = createAction(
	'[PPG Store] Change Node Side View Status',
	props<{ value: boolean }>()
);

export const ChangeDataSideViewStatus = createAction(
	'[PPG Store] Change Data Side View Status',
	props<{ value: boolean }>()
);

export const TalkAbout = createAction(
	'[PPG Store] Change Data TalkAbout',
	props<{ value: string }>()
);

export const TalkingAbout = createAction(
	'[PPG Store] Clear Data TalkAbout',
	props<{ value: string }>()
);

export const LastPPGUpdate = createAction(
	'[PPG Store] Change last update PPG',
	props<{ value: string }>()
);
export const resetPPGState = createAction(
	'[PPG Store] reset PPG state'
);