<button [type]="type" [class]="getClass">
	<maia-loader [loaderSize]="spinnerSize" [position]="'relative'" [bg-opacity]="0" class="mr-1" *ngIf="showSpinner"></maia-loader>
	<maia-icons [icon]="icon" [width]="iconSize" *ngIf="icon !== '' && !showSpinner"></maia-icons>
	<span *ngIf="label">{{label | translate}}</span>
	<ng-container *ngIf="extraLabel">
		{{extraLabel}}
	</ng-container>
</button>
<!-- <div class="flex items-center">
	<maia-button [size]="'xsmall'" [btnType]="'primary'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'xsmall'" [label]="'COMMON.next'" [btnType]="'primary'" (click)="onNext()" class="mr-1"></maia-button>
	<maia-button [size]="'xsmall'" [label]="'COMMON.next'" [btnType]="'primary'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'xsmall'" [label]="'COMMON.next'" [btnType]="'primary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'all'" class="mr-1"></maia-button>
	<maia-button [size]="'xsmall'" [label]="'COMMON.next'" [btnType]="'primary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'xsmall'" [btnType]="'primary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'xsmall'" [label]="'COMMON.next'" [btnType]="'primary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'below'"></maia-button>
</div>
<div class="flex items-center">
	<maia-button [size]="'small'" [btnType]="'secondary'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'small'" [label]="'COMMON.next'" [btnType]="'secondary'" (click)="onNext()" class="mr-1"></maia-button>
	<maia-button [size]="'small'" [label]="'COMMON.next'" [btnType]="'secondary'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'small'" [label]="'COMMON.next'" [btnType]="'secondary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'all'" class="mr-1"></maia-button>
	<maia-button [size]="'small'" [label]="'COMMON.next'" [btnType]="'secondary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'small'" [btnType]="'secondary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'small'" [label]="'COMMON.next'" [btnType]="'secondary'" (click)="onNext()" [icon]="'uknow'" [outlined]="'below'"></maia-button>

</div>
<div class="flex items-center">
	<maia-button [size]="'base'" [btnType]="'warning'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'base'" [label]="'COMMON.next'" [btnType]="'warning'" (click)="onNext()" class="mr-1"></maia-button>
	<maia-button [size]="'base'" [label]="'COMMON.next'" [btnType]="'warning'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'base'" [label]="'COMMON.next'" [btnType]="'warning'" (click)="onNext()" [icon]="'uknow'" [outlined]="'all'" class="mr-1"></maia-button>
	<maia-button [size]="'base'" [label]="'COMMON.next'" [btnType]="'warning'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'base'" [btnType]="'warning'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'base'" [label]="'COMMON.next'" [btnType]="'warning'" (click)="onNext()" [icon]="'uknow'" [outlined]="'below'"></maia-button>
</div>
<div class="flex items-center">
	<maia-button [size]="'large'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'large'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" class="mr-1"></maia-button>
	<maia-button [size]="'large'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" class="mr-1"></maia-button>
	<maia-button [size]="'large'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'all'" class="mr-1"></maia-button>
	<maia-button [size]="'large'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'large'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1"></maia-button>
	<maia-button [size]="'large'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'below'"></maia-button>
</div>
<div class="flex items-center">
	<maia-button [size]="'xlarge'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" class="mr-1" [disabled]="true"></maia-button>
	<maia-button [size]="'xlarge'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" class="mr-1" [disabled]="true"></maia-button>
	<maia-button [size]="'xlarge'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" class="mr-1" [disabled]="true"></maia-button>
	<maia-button [size]="'xlarge'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'all'" class="mr-1" [disabled]="true"></maia-button>
	<maia-button [size]="'xlarge'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1" [disabled]="true"></maia-button>
	<maia-button [size]="'xlarge'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'hidden'" class="mr-1" [disabled]="true"></maia-button>
	<maia-button [size]="'xlarge'" [label]="'COMMON.next'" [btnType]="'danger'" (click)="onNext()" [icon]="'uknow'" [outlined]="'below'" [disabled]="true"></maia-button>
</div> -->