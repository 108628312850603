import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import packageJson from '../../../package.json';
import { environment } from '@environments/environment';
import { inject } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BYPASS_LOG } from '../interceptors/auth.interceptor';
import { AppRoutes } from '@utils/routes';

interface StatusModel {
	version: string,
	forceUpdate: boolean,
	environment: string,
	scheduledMaintenance: null | string
}


export const forceRefreshGuard: CanActivateFn = (route, segments) => {
	const http = inject(HttpClient);
	const router = inject(Router);
	const service: string = window.sessionStorage.getItem('maia_service') && window.sessionStorage.getItem('maia_service') !== '' ? window.sessionStorage.getItem('maia_service')! : environment.service;
	const headers = new HttpHeaders().set('service', service);
	const context = new HttpContext().set(BYPASS_LOG, true);
	http.get<StatusModel>(`${environment.apiHostPublic}version/read/1.0.0/web/${environment.service}`, { headers, context }).subscribe((res: StatusModel) => {
		let appVersion = +packageJson.version.split('.').join('');
		let currVersion = +res.version.split('.').join('');
		window.sessionStorage.setItem('maia_service', res.environment);
		if (typeof res.scheduledMaintenance === 'string') {
			router.navigate([`/${AppRoutes.UNDER_MAINTAINANCE}`], { state: { text: res.scheduledMaintenance } });
			return false
		} else {
			if (res.forceUpdate && appVersion < currVersion) {
				router.navigate([`/${AppRoutes.FORCED_UPDATE}`]);
				return false
			}
			return false
		}
	})
	return true;
};