import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ppgActions from '../actions/personalGraph.action';
import * as ppgSelectors from '@store/selectors/personalGraph.selector';
import * as userSelectors from '@store/selectors/user.selector';
import * as userInformations from '@store/selectors/personalSettings.selector';
import { Observable, forkJoin, map, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutes } from '@utils/routes';
import { PersonalGraphService } from '@services/personal-graph.service';
import { CudNodeModel, DefaultCategoriesModel, NodeType, PpgData, PpgDataNode, PpgDictionaryModel } from '@models/personalGraph.model';
import { Store } from '@ngrx/store';
import { DecodedTokenModel } from '@models/decodedToken.model';
import { UserInformations } from '@models/setting.model';

@Injectable()
export class PersonalGraphEffects {
	$currPath: Observable<string[]> = this.store.select(ppgSelectors.getPpgPath);
	$ppgData: Observable<PpgData> = this.store.select(ppgSelectors.getPpgData);
	$ppgCurrNodeData: Observable<PpgDataNode[]> = this.store.select(ppgSelectors.getPpgCurrNodelData);
	$user = this.store.select(userSelectors.selectCurrentUser);
	$userInformation: Observable<UserInformations | null> = this.store.select(userInformations.getInformation);
	$ppgMultipleSave: Observable<CudNodeModel> = this.store.select(ppgSelectors.getPpgMultipleUpdate);
	constructor(
		private actions$: Actions,
		private router: Router,
		private store: Store,
		private ppgService: PersonalGraphService
	) { }
	GetPPGData$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ppgActions.GetPPGData),
			switchMap(actions => {
				return forkJoin({
					ppgData: this.ppgService.getGraphData(actions.value),
					ppgDictionary: this.ppgService.getPpgDictionary(),
					ppgBannedCat: this.ppgService.getBannedCat()
				}).pipe(
					map(data => {
						let lang: string;
						this.$userInformation.subscribe(userInfo => lang = userInfo?.language!);
						let tmpPpgData: PpgData = {};
						let tmpDefaultCat: DefaultCategoriesModel = {};
						let pName: PpgDataNode | undefined = data.ppgData['personal_data'].find((obj: PpgDataNode) => {
							return obj.label === 'has_name'
						})
						Object.keys(data.ppgData).forEach((val) => {
							let dictionaryObj = data.ppgDictionary.find((obj) => {
								return obj.category === val
							});
							if (dictionaryObj) {
								let transKey = dictionaryObj.category === 'personal_data' ? 'personal_data' : (dictionaryObj!['category_' + lang as keyof PpgDictionaryModel] as string)
								tmpPpgData[transKey] = data.ppgData[val]
							}
						})
						data.ppgDictionary.forEach((dictionary: PpgDictionaryModel) => {
							if (dictionary.category !== 'personal_data') {
								if (!tmpDefaultCat.hasOwnProperty(dictionary.category)) {
									tmpDefaultCat = {
										...tmpDefaultCat,
										[dictionary.category]: {
											"category_en": dictionary.category_en,
											"category_fr": dictionary.category_fr,
											"category_it": dictionary.category_it
										}

									}
								}
							}
						})
						return ppgActions.SetPPGData({ ppgData: tmpPpgData, ppgDictionary: data.ppgDictionary, ppgDefaultCat: tmpDefaultCat, ppgBannedCat: data.ppgBannedCat, personalName: pName ? pName.value : 'You' });
					})
				);
			})
		);
	});


	UpdatePPGBannedCat$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ppgActions.UpdatePPGBannedCat),
			switchMap((action) => {
				let user: DecodedTokenModel;
				this.$user.subscribe(res => { user = res })
				return this.ppgService.updateBannedCat(action.value).pipe(
					map(() => {
						return ppgActions.GetPPGData({ value: user!.oid })
					})
				)
			})
		);
	});


	SetPPGCurrNode$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ppgActions.SetPPGCurrNode),
			map((action) => {
				let currPath: string[] = [];
				this.$currPath.subscribe(res => { currPath = res });
				let ppgData: PpgData = {};
				this.$ppgData.subscribe(res => { ppgData = res });
				if (action.value === 'You') {
					currPath = [
						action.value
					]
				} else {
					if (Object.keys(ppgData).indexOf(action.value) > 0) {
						currPath = [
							'You',
							action.value
						]
					} else {

					}
				}
				return ppgActions.SetPPGPath({ value: currPath })
			})
		);
	});

	SetPPGCurrSubNode$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ppgActions.SetPPGCurrSubNode),
			map((action) => {
				let currPath: string[] = [];
				this.$currPath.subscribe(res => { currPath = res });
				let personalData: PpgDataNode[] = [];
				this.$ppgCurrNodeData.subscribe(res => { personalData = res });
				let currPdata = '';
				let currRel = '';
				let node = personalData.find((pdata) => (pdata.id === action.value))
				if (node!.obj_type !== NodeType.SELECT) {
					currPdata = node?.value;
				} else {
					this.$userInformation.subscribe((val) => {
						let index = JSON.parse(node!.placeholder_en.toLocaleLowerCase()).indexOf(node!.value)
						switch (val?.language) {
							case 'it':
								return currPdata = JSON.parse(node!.placeholder_it)[index]
							case 'fr':
								return currPdata = JSON.parse(node!.placeholder_fr)[index]
							case 'en':
								return currPdata = JSON.parse(node!.placeholder_en)[index]
						}
						return currPdata
					});
				}
				this.$userInformation.subscribe((val) => {
					switch (val?.language) {
						case 'it':
							return currRel = personalData.find((pdata) => (pdata.id === action.value))!.relationship_it;
						case 'fr':
							return currRel = personalData.find((pdata) => (pdata.id === action.value))!.relationship_fr;
						case 'en':
							return currRel = personalData.find((pdata) => (pdata.id === action.value))!.relationship_en;
					}
					return currRel
				});
				currPdata += `-${currRel}`;
				if (currPath.length === 2) {
					currPath = [...currPath, currPdata]
				} else {
					currPath = [...currPath]
					currPath[currPath.length - 1] = currPdata
				}
				return ppgActions.SetPPGPath({ value: currPath })
			})
		);
	})


	SavePpgMultiple$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ppgActions.SavePpgMultiple),
			tap(() => {
				this.store.dispatch(ppgActions.IsSavingPpgMultiple({ value: true }));
			}),
			switchMap(() => {
				let user: DecodedTokenModel;
				this.$user.subscribe(res => { user = res })
				let cudNode: CudNodeModel = {
					deleteList: [],
					createList: [],
					updateList: [],
					id_user: ''
				}
				this.$ppgMultipleSave.subscribe(res => {
					cudNode = {
						...cudNode,
						updateList: [
							...res.updateList
						],
						deleteList: [
							...res.deleteList
						],
						createList: [
							...res.createList
						],
						id_user: res.id_user
					}

				});
				return this.ppgService.updatePpgCudUser(cudNode).pipe(
					map(() => {
						return ppgActions.GetPPGData({ value: user.oid })
					})
				)

			})
		);
	});

	DeletePpgNode$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ppgActions.DeletePpgNode),
			switchMap((action) => {
				return this.ppgService.updatePpgCudUser(action.value).pipe(
					map(() => {
						return ppgActions.GetPPGData({ value: action.value.id_user })
					})
				)
			})
		);
	});
}


// function isBoolean(value: string) {
// 	return value.toLowerCase().trim() === 'true' || value.toLowerCase().trim() === 'false';
// }