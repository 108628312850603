import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'maia-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
	@Input() label!: string;
	@Input() extraLabel: string | null = null;
	@Input() size: 'xsmall' | 'small' | 'base' | 'large' | 'xlarge' = 'base';
	@Input() type: 'button' | 'submit' = 'button';
	@Input() btnType: 'primary' | 'secondary' | 'warning' | 'danger' | 'white' = 'primary';
	@Input() rounded: 'all' | 'none' | 'below' | 'above' | 'start' | 'end' = 'all';
	@Input() outlined: 'all' | 'below' | 'above' | 'start' | 'end' | 'none' | 'hidden' = 'none';
	@Input() direction: 'row' | 'col' = 'row';
	@Input() justify: 'justify-center' | 'justify-start' = 'justify-center';
	@Input() showSpinner: boolean = false;
	@Input() icon: string = '';
	@Input() disabled: boolean = false;
	iconSize!: string;
	spinnerSize!: number;
	classArray!: string[];
	elementRef: ElementRef;
	constructor(
		elRef: ElementRef
	) {
		this.elementRef = elRef;
	}


	@HostBinding("class") get isDisabled() {
		return this.disabled ? 'pointer-events-none' : '';
	};
	get getClass(): string {
		this.classArray = ['relative z-0 overflow-hidden w-full h-full flex flex-nowrap whitespace-nowrap outlined-none items-center leading-none transition-all font-semibold', this.justify];



		if (this.direction === 'col') {
			this.classArray = [...this.classArray, 'flex-cols justify-center']
		}

		switch (this.size) {
			case 'xsmall':
				switch (this.buttonElement) {
					case 'both':
						this.classArray = [...this.classArray, 'py-1 pl-1 pr-1.5', 'text-xs', 'rounded-md'];
						this.iconSize = '16px';
						this.spinnerSize = 16;
						break
					case 'label':
					case 'extraLabel':
						this.classArray = [...this.classArray, 'p-1.5', 'text-xs', 'rounded-md'];
						this.spinnerSize = 10;
						break
					case 'icon':
						this.classArray = [...this.classArray, 'p-1', 'rounded-md'];
						this.iconSize = '16px';
						this.spinnerSize = 16;
						break
					case 'iconOnly':
						this.classArray = [...this.classArray, 'rounded-md'];
						this.iconSize = '16px';
						this.spinnerSize = 16;
						break
				}
				break
			case 'small':
				switch (this.buttonElement) {
					case 'both':
						this.classArray = [...this.classArray, 'py-1 pl-1 pr-1.5', 'text-sm', 'rounded-md'];
						this.iconSize = '22px';
						this.spinnerSize = 22;
						break
					case 'label':
					case 'extraLabel':
						this.classArray = [...this.classArray, 'p-2', 'text-sm', 'rounded-md'];
						this.spinnerSize = 12;
						break
					case 'icon':
						this.classArray = [...this.classArray, 'p-1', 'rounded-md'];
						this.iconSize = '22px';
						this.spinnerSize = 22;
						break
					case 'iconOnly':
						this.classArray = [...this.classArray, 'rounded-md'];
						this.iconSize = '22px';
						this.spinnerSize = 22;
						break
				}
				break
			case 'base':
				switch (this.buttonElement) {
					case 'both':
						this.classArray = [...this.classArray, 'py-1 pl-2 pr-2.5', 'text-base', 'rounded-md'];
						this.iconSize = '28px';
						this.spinnerSize = 28;
						break
					case 'label':
					case 'extraLabel':
						this.classArray = [...this.classArray, 'p-2.5', 'text-base', 'rounded-lg'];
						this.spinnerSize = 16;
						break
					case 'icon':
						this.classArray = [...this.classArray, 'p-1.5', 'rounded-lg'];
						this.iconSize = '24px';
						this.spinnerSize = 28;
						break
					case 'iconOnly':
						this.classArray = [...this.classArray, 'rounded-lg'];
						this.iconSize = '24px';
						this.spinnerSize = 28;
						break
				}
				break
			case 'large':
				switch (this.buttonElement) {
					case 'both':
						this.classArray = [...this.classArray, 'py-1 pl-2 pr-2.5', 'text-lg', 'rounded-md'];
						this.iconSize = '34px';
						this.spinnerSize = 34;
						break
					case 'label':
					case 'extraLabel':
						this.classArray = [...this.classArray, 'p-3', 'text-lg', 'rounded-lg'];
						this.spinnerSize = 18;
						break
					case 'icon':
						this.classArray = [...this.classArray, 'p-1.5', 'rounded-lg'];
						this.iconSize = '28px';
						this.spinnerSize = 28;
						break
					case 'iconOnly':
						this.classArray = [...this.classArray, 'rounded-lg'];
						this.iconSize = '28px';
						this.spinnerSize = 28;
						break
				}
				break
			case 'xlarge':
				switch (this.buttonElement) {
					case 'both':
						this.classArray = [...this.classArray, 'py-1 pl-3 pr-3.5', 'text-xl', 'rounded-md'];
						this.iconSize = '40px';
						this.spinnerSize = 40;
						break
					case 'label':
					case 'extraLabel':
						this.classArray = [...this.classArray, 'p-3.5', 'text-xl', 'rounded-lg'];
						this.spinnerSize = 22;
						break
					case 'icon':
						this.classArray = [...this.classArray, 'p-1.5', 'rounded-lg'];
						this.iconSize = '36px';
						this.spinnerSize = 36;
						break
					case 'iconOnly':
						this.classArray = [...this.classArray, 'rounded-lg'];
						this.iconSize = '36px';
						this.spinnerSize = 36;
						break
				}
				break
		}

		switch (this.outlined) {
			case 'all':
				this.classArray = [...this.classArray, 'border-2'];
				break
			case 'below':
				this.classArray = [...this.classArray, 'border-b-2', 'rounded-b-none'];
				break
			case 'above':
				this.classArray = [...this.classArray, 'border-t-2', 'rounded-t-none'];
				break
			case 'start':
				this.classArray = [...this.classArray, 'border-l-2', 'rounded-l-none'];
				break
			case 'end':
				this.classArray = [...this.classArray, 'border-r-2', 'rounded-r-none'];
				break
			case 'hidden':
				this.classArray = [...this.classArray];
				break
		}
		switch (this.btnType) {
			case 'primary':
				if (this.outlined === 'none') {
					this.classArray = [...this.classArray, 'bg-maia-petrol-800 text-white hover:bg-maia-petrol-700']
				} else if (this.outlined === 'hidden') {
					this.classArray = [...this.classArray, 'text-maia-petrol-800 hover:text-maia-petrol-700']
				} else {
					this.classArray = [...this.classArray, 'border-maia-petrol-800 text-maia-petrol-800 hover:border-maia-petrol-700 hover:text-maia-petrol-700 bg-white']
				}
				break
			case 'secondary':
				if (this.outlined === 'none') {
					this.classArray = [...this.classArray, 'bg-gray-500 text-white hover:bg-gray-400']
				} else if (this.outlined === 'hidden') {
					this.classArray = [...this.classArray, 'text-gray-500 hover:text-gray-400']
				} else {
					this.classArray = [...this.classArray, 'border-gray-500 text-gray-500 hover:border-gray-400 hover:text-gray-400 bg-white']
				}
				break
			case 'warning':
				if (this.outlined === 'none') {
					this.classArray = [...this.classArray, 'bg-orange-500 text-white hover:bg-orange-400']
				} else if (this.outlined === 'hidden') {
					this.classArray = [...this.classArray, 'text-orange-500 hover:text-orange-400']
				} else {
					this.classArray = [...this.classArray, 'border-orange-500 text-orange-500 hover:border-orange-400 hover:text-orange-400 bg-white']
				}
				break
			case 'danger':
				if (this.outlined === 'none') {
					this.classArray = [...this.classArray, 'bg-red-700 text-white hover:bg-red-600']
				} else if (this.outlined === 'hidden') {
					this.classArray = [...this.classArray, 'text-red-700 hover:text-red-600']
				} else {
					this.classArray = [...this.classArray, 'border-red-700 text-red-700 hover:border-red-600 hover:text-red-600 bg-white']
				}
				break
			case 'white':
				if (this.outlined === 'none') {
					this.classArray = [...this.classArray, 'bg-white text-white hover:text-opacity-70']
				} else if (this.outlined === 'hidden') {
					this.classArray = [...this.classArray, 'text-white hover:text-opacity-70']
				} else {
					this.classArray = [...this.classArray, 'border-white text-white hover:border-opacity-70 hover:text-text-opacity-70']
				}
				break
		}
		if (this.isDisabled) {
			if (this.outlined === 'hidden') {
				this.classArray = [...this.classArray, 'opacity-40']
			} else {
				this.classArray = [...this.classArray, 'after:block after:content[\'\'] after:absolute after:top-0 after:bottom-0 after:right-0 after:left-0 after:bg-white after:bg-opacity-50 after:z-[-1] border-opacity-40 text-opacity-50']
			}
		}
		return this.classArray.join(' ');
	}

	get buttonElement(): string {
		if (this.label && this.icon) {
			return 'both'
		}
		if (this.label && !this.icon) {
			return 'label'
		}
		if (!this.label && this.icon && this.outlined !== 'hidden') {
			return 'icon'
		}
		if (!this.label && this.icon && this.outlined === 'hidden') {
			return 'iconOnly'
		}
		return 'none'
	}
}
