import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ChatService } from "@services/chat.service";
import { DOCUMENT } from '@angular/common';
import { map } from "rxjs";
import { ConvDriveGetAttachmentsModel } from "@models/chat.model";


export const uknowResolver: ResolveFn<ConvDriveGetAttachmentsModel | null> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	let document = inject(DOCUMENT).location;
	let host = document.href.replace(document.protocol + '//', '');
	//let host = 'marco-baccanelli.mymaia.ai/uknow/origine-di-maia';
	return inject(ChatService).uKnowReadAttachments(host).pipe(map((res: ConvDriveGetAttachmentsModel) => {
		return res
	}))
};