import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
@Component({
	selector: 'maia-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
	@Input('position') position: string = 'relative';
	@Input('bg-color') bgColor: string = 'bg-maia-petrol-800';
	@Input('bg-opacity') bgOpacity: number = 50;
	@Input('loaderSize') loaderSize: number = 80;
	@HostBinding('class') classAttribute: string = '';
	constructor() { }
	ngOnInit(): void {
		this.updateClassAttribute();
	}
	private updateClassAttribute() {
		const classes: string[] = [];
		classes.push(this.position);
		classes.push(this.bgColor);
		classes.push('bg-opacity-' + this.bgOpacity);
		this.classAttribute = classes.join(' ');
	}
}
