import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'maia-icons',
	templateUrl: './icons.component.html',
	styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {
	@Input({ required: true }) icon!: string;
	@Input() width: string = '48px';
	@Input() height!: string;

	ngOnInit(): void {
		if (!this.height) this.height = this.width;
	}
}
