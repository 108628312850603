<div [ngStyle]="{'width':loaderSize+'px'}" class=" w-full max-w-[150px] m-autobg-white rounded-full flex items-center justify-center relative before:content-[''] before:block before:w-full before:pt-[100%] bg-white">
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 560.18 493.51" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%]">
		<defs>
			<linearGradient id="fill-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" stop-color="var(--maia-petrol-800)" />
				<stop offset="100%" stop-color="var(--maia-petrol-800)" />
			</linearGradient>
			<mask id="mask">
				<rect x="0" y="0" width="100%" height="100%" fill="black" />
				<rect x="0" y="0" width="0" height="100%" fill="white" class="mask-rect" />
			</mask>
		</defs>
		<path d="M547.17 127.68c9.03-14.12 13.89-31.16 12.88-49.35-2.29-41.22-35.58-75.13-76.76-78.1-46.44-3.35-85.48 31.49-88.96 76.31-5.08 64.59-37.97 122.54-88.28 160.14-15.32 11.45-36.61 11.45-51.93 0-50.31-37.6-83.2-95.55-88.28-160.14C162.37 31.72 123.33-3.13 76.89.22 35.71 3.19 2.42 37.1.13 78.32c-1.01 18.19 3.85 35.23 12.88 49.35 46.26 72.63 46.26 165.49 0 238.16C3.98 379.95-.87 396.97.13 415.15c2.31 41.84 36.54 76.01 78.38 78.24 47.93 2.56 87.61-35.57 87.61-82.94 0-16.41-4.77-31.72-13.01-44.62-27.33-42.94-38.52-92.94-33.53-141.65.98-9.59 12.7-13.51 19.25-6.45a221.406 221.406 0 0158.46 133.29l.07-.06c1.94 24.75 14.8 48.52 37.69 63.34 33.33 21.59 78.34 16.14 105.5-12.84 13.69-14.6 20.89-32.44 22.28-50.49l.05.04c4.01-50.75 25.15-97.4 58.46-133.29 6.56-7.07 18.27-3.15 19.25 6.45 4.99 48.71-6.2 98.71-33.53 141.65-9.03 14.12-13.88 31.14-12.88 49.32 2.31 41.84 36.54 76.01 78.39 78.23 47.93 2.55 87.6-35.57 87.6-82.94 0-16.41-4.77-31.72-13.01-44.62-46.26-72.67-46.26-165.53 0-238.16z" fill="#212427" />
		<path d="M547.17 127.68c9.03-14.12 13.89-31.16 12.88-49.35-2.29-41.22-35.58-75.13-76.76-78.1-46.44-3.35-85.48 31.49-88.96 76.31-5.08 64.59-37.97 122.54-88.28 160.14-15.32 11.45-36.61 11.45-51.93 0-50.31-37.6-83.2-95.55-88.28-160.14C162.37 31.72 123.33-3.13 76.89.22 35.71 3.19 2.42 37.1.13 78.32c-1.01 18.19 3.85 35.23 12.88 49.35 46.26 72.63 46.26 165.49 0 238.16C3.98 379.95-.87 396.97.13 415.15c2.31 41.84 36.54 76.01 78.38 78.24 47.93 2.56 87.61-35.57 87.61-82.94 0-16.41-4.77-31.72-13.01-44.62-27.33-42.94-38.52-92.94-33.53-141.65.98-9.59 12.7-13.51 19.25-6.45a221.406 221.406 0 0158.46 133.29l.07-.06c1.94 24.75 14.8 48.52 37.69 63.34 33.33 21.59 78.34 16.14 105.5-12.84 13.69-14.6 20.89-32.44 22.28-50.49l.05.04c4.01-50.75 25.15-97.4 58.46-133.29 6.56-7.07 18.27-3.15 19.25 6.45 4.99 48.71-6.2 98.71-33.53 141.65-9.03 14.12-13.88 31.14-12.88 49.32 2.31 41.84 36.54 76.01 78.39 78.23 47.93 2.55 87.6-35.57 87.6-82.94 0-16.41-4.77-31.72-13.01-44.62-46.26-72.67-46.26-165.53 0-238.16z" fill="url(#fill-gradient)" mask="url(#mask)" />
	</svg>
</div>