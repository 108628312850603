import { Component } from '@angular/core';

@Component({
  selector: 'maia-iconcontainer',
  templateUrl: './iconcontainer.component.html',
  styleUrls: ['./iconcontainer.component.scss']
})
export class IconcontainerComponent {

}
