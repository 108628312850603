import { AfterViewInit, Component, EventEmitter, HostListener, Inject, NgZone, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalRef } from './modal-ref';
import { MODAL_DATA } from './modal-tokens';
import { AnimationEvent } from '@angular/animations';
import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Subscription, take } from 'rxjs';
import { ButtonType } from '@models/generic.model';
import { modalAnimation } from '@utils/animations';
import { Router } from '@angular/router';

@Component({
	selector: 'maia-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: [modalAnimation],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
	@ViewChild(CdkPortalOutlet) portalOutlet!: CdkPortalOutlet;

	loading = true;
	animationState: 'void' | 'enter' | 'leave' = 'enter';
	animationStateChanged = new EventEmitter<AnimationEvent>();

	btnType: typeof ButtonType = ButtonType;

	@HostListener('document:keydown', ['$event'])
	private handleKeydown(event: KeyboardEvent) {
		if (event.key === 'Escape' && !this.data.disableClose) {
			this.dialogRef.close();
		}
	}

	@HostListener('window:popstate', ['$event'])
	private dismissModal() {
		this.dialogRef.close();
	}


	private subscriptions = new Subscription();

	constructor(
		public dialogRef: ModalRef,
		@Inject(MODAL_DATA) public data: any,
		public viewContainerRef: ViewContainerRef,
		private _ngZone: NgZone,
		private router: Router
	) { }

	ngAfterViewInit(): void {
		this._ngZone.onStable.pipe(take(1)).subscribe(() => {
			if (this.dialogRef.inModalElement instanceof TemplateRef) {
				let portal = new TemplatePortal(
					this.dialogRef.inModalElement,
					this.viewContainerRef
				);
				this.portalOutlet.attachTemplatePortal(portal);
			} else {
				let portal = new ComponentPortal(this.dialogRef.inModalElement);
				this.portalOutlet.attachComponentPortal(portal);
			}
		});
		this.subscriptions.add(
			this.router.events.subscribe(() => this.dialogRef.close())
		)
	}

	onAnimationStart(event: AnimationEvent) {
		this.animationStateChanged.emit(event);
	}

	onAnimationDone(event: AnimationEvent) {
		this.animationStateChanged.emit(event);
	}

	startExitAnimation() {
		this.animationState = 'leave';
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
